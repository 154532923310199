/* prevent pull-to-refresh for Safari 16+ */
@media screen and (pointer: coarse) {
  @supports (-webkit-backdrop-filter: blur(1px)) and (overscroll-behavior-y: none) {
    html {
      overscroll-behavior-y: none;
    }
    body {
      background-color: #fff;
    }
  }
}
/* prevent pull-to-refresh for Safari 9~15 */
@media screen and (pointer: coarse) {
  @supports (-webkit-backdrop-filter: blur(1px)) and (not (overscroll-behavior-y: none)) {
    html {
      overflow: hidden;
    }
    body {
      margin: 0px;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      background-color: #fff;
      /* max-height:100% or `height: calc(100% - 16px);` if body has default margin */
    }
  }
}
body {
  margin: 0;
  font-family: 'Monospaced Number', 'Chinese Quote', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: none;
  height: 100px;
}
*,
:after,
:before {
  box-sizing: border-box;
}
.ant-avatar > img {
  object-fit: contain;
}
