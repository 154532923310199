.index_loading__YWcOv {
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
}

.index_header__1RiGs {
  height: 64px;
  padding: 0 12px 0 0;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  position: relative;
}
.index_header__1RiGs .index_trigger__3eL5R {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
  float: left;
}
.index_header__1RiGs .index_trigger__3eL5R:hover {
  color: #1890ff;
}
.index_header__1RiGs .index_logo__1uFkY {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}
.index_center__3pi97 {
  float: left;
}
.index_right__2ZifS {
  height: 100%;
  float: right;
}
.index_right__2ZifS .index_name__2NzCw {
  padding-left: 10px;
}
.index_right__2ZifS .index_action__3zyBy {
  cursor: pointer;
  padding: 0 12px;
  display: inline-block;
  transition: all 0.3s;
  height: 100%;
}
.index_right__2ZifS .index_action__3zyBy > i {
  font-size: 16px;
  vertical-align: middle;
  color: #434e59;
}
.index_right__2ZifS .index_action__3zyBy:hover,
.index_right__2ZifS .index_action__3zyBy.ant-popover-open {
  background: #1890ff;
}
.index_right__2ZifS .index_search__2hn8o {
  padding: 0;
  margin: 0 12px;
}
.index_right__2ZifS .index_search__2hn8o:hover {
  background: transparent;
}
.index_right__2ZifS .index_account__1mAYC .index_avatar__1j5ua {
  background-color: #1890ff;
  vertical-align: middle;
}

.index_trigger__2M79x {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}
.index_trigger__2M79x:hover {
  color: #1879FE;
}
.index_logoContainer__2WryJ {
  height: 64px;
  position: relative;
  line-height: 64px;
  padding-left: 24px;
  transition: all 0.3s;
  background: #002140;
  overflow: hidden;
}
.index_logoContainer__2WryJ .index_logo__1TGeF {
  display: inline-block;
  vertical-align: middle;
  height: 36px;
}
.index_logoContainer__2WryJ h1 {
  color: #fff;
  display: inline-block;
  vertical-align: middle;
  font-size: 20px;
  margin: 0 0 0 12px;
  font-family: "Myriad Pro", "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-weight: 600;
}
.index_menu__2FZp6 {
  padding-bottom: 60px;
}

.index_exception__2Gtpu {
  display: flex;
  align-items: center;
  height: 100%;
}
.index_exception__2Gtpu .index_imgBlock__1J3Zz {
  flex: 0 0 62.5%;
  width: 62.5%;
  padding-right: 152px;
  zoom: 1;
}
.index_exception__2Gtpu .index_imgBlock__1J3Zz:before,
.index_exception__2Gtpu .index_imgBlock__1J3Zz:after {
  content: " ";
  display: table;
}
.index_exception__2Gtpu .index_imgBlock__1J3Zz:after {
  clear: both;
  visibility: hidden;
  font-size: 0;
  height: 0;
}
.index_exception__2Gtpu .index_imgEle__3n37a {
  height: 360px;
  width: 100%;
  max-width: 430px;
  float: right;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
}
.index_exception__2Gtpu .index_content__2qoXO {
  flex: auto;
}
.index_exception__2Gtpu .index_content__2qoXO h1 {
  color: #434e59;
  font-size: 72px;
  font-weight: 600;
  line-height: 72px;
  margin-bottom: 24px;
}
.index_exception__2Gtpu .index_content__2qoXO .index_desc__1fHaU {
  color: #434e59;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 16px;
}
.index_exception__2Gtpu .index_content__2qoXO .index_actions__33uF1 button:not(:last-child) {
  margin-right: 8px;
}
@media screen and (max-width: 1280px) {
  .index_exception__2Gtpu .index_imgBlock__1J3Zz {
    padding-right: 88px;
  }
}
@media screen and (max-width: 640px) {
  .index_exception__2Gtpu {
    display: block;
    text-align: center;
  }
  .index_exception__2Gtpu .index_imgBlock__1J3Zz {
    padding-right: 0;
    margin: 0 auto 24px;
  }
}
@media screen and (max-width: 480px) {
  .index_exception__2Gtpu .index_imgBlock__1J3Zz {
    margin-bottom: -24px;
    overflow: hidden;
  }
}

.ant-radio-group-outline {
  display: none;
}
.PCLayout_footer__3nXng {
  position: absolute;
  bottom: 10px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.2);
  text-align: center;
  width: 100%;
}
.PCLayout_row__NATTg {
  margin-top: 10px;
  width: 100%;
}
.PCLayout_colLeft__2Djpy {
  margin-left: 5px;
  line-height: 40px;
}
@media screen and (min-width: 1001px) {
  .PCLayout_colRight__3cRIL {
    text-align: right;
    margin-right: 5px;
    line-height: 40px;
  }
}
@media screen and (max-width: 1000px) {
  .PCLayout_colRight__3cRIL {
    text-align: left;
    margin-left: 5px;
    line-height: 40px;
  }
}
.PCLayout_colorGray__3I-zG {
  color: gray;
}
.PCLayout_toolButton__2rrVQ {
  width: 110px;
}

.Wedding_img__3wWvu {
  border: 2px solid white;
}
.Wedding_title__3AAI9 {
  margin-left: 2vw;
  margin-top: 20px;
  height: 30px;
}
.Wedding_text__1hLZ9 {
  margin-left: 2vw;
}
.Wedding_squre_img__3io_D {
  border: 2px solid white;
  width: 33vw;
  height: 33vw;
  object-fit: cover;
}

/* prevent pull-to-refresh for Safari 16+ */
@media screen and (pointer: coarse) {
  @supports (-webkit-backdrop-filter: blur(1px)) and (overscroll-behavior-y: none) {
    html {
      overscroll-behavior-y: none;
    }
    body {
      background-color: #fff;
    }
  }
}
/* prevent pull-to-refresh for Safari 9~15 */
@media screen and (pointer: coarse) {
  @supports (-webkit-backdrop-filter: blur(1px)) and (not (overscroll-behavior-y: none)) {
    html {
      overflow: hidden;
    }
    body {
      margin: 0px;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      background-color: #fff;
      /* max-height:100% or `height: calc(100% - 16px);` if body has default margin */
    }
  }
}
body {
  margin: 0;
  font-family: 'Monospaced Number', 'Chinese Quote', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: none;
  height: 100px;
}
*,
:after,
:before {
  box-sizing: border-box;
}
.ant-avatar > img {
  object-fit: contain;
}

