// @import "~antd/lib/style/themes/default.less";

.header {
  height: 64px;
  padding: 0 12px 0 0;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  position: relative;

  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
    float: left;
  }

  .trigger:hover {
    color: #1890ff;
  }

  .logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
  }
}

.center {
  float: left;
}

.right {
  height: 100%;
  float: right;

  .name {
    padding-left: 10px;
  }

  .action {
    cursor: pointer;
    padding: 0 12px;
    display: inline-block;
    transition: all 0.3s;
    height: 100%;

    >i {
      font-size: 16px;
      vertical-align: middle;
      color: #434e59;
    }

    &:hover,
    &:global(.ant-popover-open) {
      background: #1890ff;
    }
  }

  .search {
    padding: 0;
    margin: 0 12px;

    &:hover {
      background: transparent;
    }
  }

  .account {
    .avatar {
      background-color: #1890ff;
      vertical-align: middle;
    }
  }
}