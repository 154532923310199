.img {
  border: 2px solid white;
}

.title {
  margin-left: 2vw;
  margin-top: 20px;
  height: 30px;
}

.text {
  margin-left: 2vw;
}

.squre_img {
  border: 2px solid white;
  width: 33vw;
  height: 33vw;
  object-fit: cover;
}