//layout样式
:global {
  .ant-radio-group-outline {
    display: none;
  }
}

.footer {
  position: absolute;
  bottom: 10px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.2);
  text-align: center;
  width: 100%;
}

.row {
  margin-top: 10px;
  width: 100%;
}

.colLeft {
  margin-left: 5px;
  line-height: 40px
}

@media screen and (min-width:1001px) {
  .colRight {
    text-align: right;
    margin-right: 5px;
    line-height: 40px;
  }
}

@media screen and (max-width:1000px) {
  .colRight {
    text-align: left;
    margin-left: 5px;
    line-height: 40px;
  }
}

.colorGray {
  color: gray;
}

.toolButton {
  width: 110px;
}